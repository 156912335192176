import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "../index.css";

// import Header from "./Components/Header";
import FeedBackForm from "../Components/FeedbackForm";

import { CSSTransition, TransitionGroup } from "react-transition-group";

function Interface() {
  return (
    <Router>
      <Route
        render={({ location }) => (
          <TransitionGroup className="transition-group">
            <CSSTransition key={location.key} timeout={300} classNames="fade">
              <section className="route-section">
                <Switch location={location}>
                  <Route path="/:id" component={FeedBackForm}></Route>
                </Switch>
              </section>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
    </Router>
  );
}

export default Interface;
