import React from "react";
import "./index.css";
import Interface from "./navigation/Interface";

function App() {
  return (
    <div>
      <Interface />
    </div>
  );
}

export default App;
