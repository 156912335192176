import React from "react";
import config from "../config";

//1. Create an async function below with a variable called response which awaits the fetch from the API URL.
//The API call URL goes in the back ticks. Then a variable called data is created which will convert the response to JSON.
//That converted data will then be returned.
//Now create an index.js file within the routes folder for the specific API call.

const openTicket = async (id, clientEmail) => {
  const response = await fetch(`${config.appURL}/emails/open?id=${id}`, {
    method: "POST",
    body: JSON.stringify({
      subject: "Unity Ticket Created",
      header: "<h1><center>Thank you for submitting a ticket!</center></h1>",
      body:
        "<p><center>Your ticket reference is:" +
        "" +
        id +
        ". If you have any additional questions please do not hesitate to contact us at <strong>020 3141 5882</strong></center></p>",
      callFrom: "Unity Ticket",
      to: clientEmail,
      smtp_id: "5",
    }),
    credentials: "include", // fetch won't send cookies unless you set credentials
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  const data = await response.json();
  console.log(response);
  return data;
};

const closedTicket = async (clientEmail) => {
  console.log("HELLLLLOOOO");
  const response = await fetch(`${config.appURL}/emails/closed`, {
    method: "POST",
    body: JSON.stringify({
      subject: "Unity Ticket Closed",
      header: "<h2>Your ticket has been closed!</h2>",
      body:
        "We love hearing feedback from all our customers. We’d appreciate it if you could take just one minute to provide your honest feedback about your experience with your recent ticket. Your thoughts will help us to build the best possible customer experience, the team at Agile read every response. Thank you! Please click visit the following link: <strong>https://unity.feedback.mybe.software/</strong> and fill in our feedback form",
      callFrom: "Unity Ticket",
      to: clientEmail,
      smtp_id: "5",
    }),
    credentials: "include", // fetch won't send cookies unless you set credentials
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  const data = await response.json();

  return data;
};

const feedbackEmail = async () => {
  console.log("Feedback Sent");
  const response = await fetch(`${config.appURL}/emails/feedback`, {
    method: "POST",
    body: JSON.stringify({
      subject: "Unity Ticket Feedback",
      header: "<h2>Ticket Name</h2>",
      body:
        "The Unity team were fantastic with how they handled my query. I would highly recommend them. Thank you. I score you <strong>Very Good<strong",
      callFrom: "Unity Ticket",
      to: "matthew.rowney@redrobotsystems.co.uk",
      smtp_id: "5",
    }),
    credentials: "include", // fetch won't send cookies unless you set credentials
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  const data = await response.json();

  return data;
};

const sendWithKey = async (obj) => {
  const response = await fetch(`${config.appURL}/emails/sendWithKey`, {
    method: "POST",
    body: JSON.stringify(obj),
    credentials: "include", // fetch won't send cookies unless you set credentials
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  const data = await response.json();

  return data;
};

export { openTicket, closedTicket, feedbackEmail, sendWithKey };
