import React, { useState } from "react";
import ApiLoader from "./ApiLoader";
import {
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import VeryBad from "../assets/Very_Bad.png";
import Bad from "../assets/Bad.png";
import Okay from "../assets/Okay.png";
import Good from "../assets/Good.png";
import VeryGood from "../assets/Very_Good.png";
import FeedbackMessage from "./FeedbackMessage";
import { sendWithKey } from "../api/email";

const FeedbackForm = (props) => {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState({ status: false });
  const [success, setSuccess] = useState(false);

  const key = props.match.params.id;

  const vBad = require("../assets/Very_Bad.png")

  const validate = {
    name: true,
    feedback: true,
  };

  const validation = async (obj) => {
    const x = {};
    Object.keys(obj).map((key) => {
      if (validate[key]) {
        if (!obj[key]) {
          x[key] = true;
        }
      }
    });

    if (Object.keys(x).length > 0) {
      return x;
    } else {
      return true;
    }
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleInputChange = (event) => {
    // event.persist();
    setData((data) => ({
      ...data,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    const x = await validation(data);
    if (x == true) {
      const fbackData = {
        secret: key,
        body: `<p>Experience : ${data.rating}</p><p>Customer Name: ${data.name}</p><p>Ticket Reference: ${data.reference}</p><p>Feedback: <br /> ${data.feedback}`,
      };
      const sendData = await sendWithKey(fbackData);
      if (sendData.statusCode === 400) {
        setSuccess("You have already submitted feedback for this ticket");
      } else if (sendData.statusCode === 404) {
        setSuccess("Feedback has already been submitted for this ticket!");
      } else {
        setSuccess("Thankyou for sending your feedback!");
      }
    }
  };

  if (!success) {
    return (
      <div className="feedbackForm">
        <ApiLoader status={loading.status} data={loading.data} />
        <h1>How did we do?</h1>
        <hr width="20%" />
        <div className="ratings">
          <RadioGroup name="rating">
            <div className="veryBad">
              <FormControlLabel
                value="Very Bad"
                name="rating"
                control={<Radio />}
                onChange={(e) => handleInputChange(e)}
              />
              <img src={VeryBad} />
            </div>

            <div className="bad">
              <FormControlLabel
                name="rating"
                value="Bad"
                control={<Radio />}
                onChange={(e) => handleInputChange(e)}
              />
              <img src={Bad} />
            </div>

            <div className="okay">
              <FormControlLabel
                name="rating"
                value="Okay"
                control={<Radio />}
                onChange={(e) => handleInputChange(e)}
              />
              <img src={Okay} />
            </div>

            <div className="good">
              <FormControlLabel
                value="Good"
                name="rating"
                control={<Radio />}
                onChange={(e) => handleInputChange(e)}
              />
              <img src={Good} />
            </div>

            <div className="veryGood">
              <FormControlLabel
                value="Very Good"
                name="rating"
                control={<Radio />}
                onChange={(e) => handleInputChange(e)}
              />
              <img src={VeryGood} />
            </div>
          </RadioGroup>
        </div>
        <div className="formarea">
          <div className="feedbackInput">
            <TextField
              label="What is your name?"
              type="text"
              name="name"
              value={data.name}
              onChange={(e) => handleChange(e)}
              placeholder="What is your name?"
              fullWidth
            />
          </div>

          <br />

          <div className="feedbackInput">
            <TextField
              label="What is your ticket reference number?"
              type="text"
              name="reference"
              value={data.reference}
              onChange={(e) => handleChange(e)}
              placeholder="What is your ticket reference number?"
              fullWidth
            />
          </div>

          <br />

          <div className="feedbackInput">
            <TextField
              label="Leave us some feedback below:"
              type="text"
              name="feedback"
              value={data.feedback}
              onChange={(e) => handleChange(e)}
              placeholder="Enter your feedback here..."
              multiline
              fullWidth
            />
          </div>
        </div>
        <div className="formButtons">
          <button id="submit" value="submit" onClick={() => handleSubmit()}>
            SEND
          </button>
        </div>
      </div>
    );
  } else {
    return <FeedbackMessage msg={success} />;
  }
};
export default FeedbackForm;
