import { createStyles } from "@material-ui/core";
import React, { useContext } from "react";
import Loader from "react-loader-spinner";

export default function ApiLoader(props) {
  if (props.status) {
    return (
      <div style={styles.loaderBackground}>
        <div style={styles.loaderContainer}>
          <Loader
            type="TailSpin"
            color="midnightblue"
            height={100}
            width={100}
          />
          <b>{props.data}</b>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

const styles = createStyles({
  loaderBackground: {
    position: "fixed",
    zIndex: "99999999",
    background: "rgb(255 255 255 / 98%)",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
  },
  loaderContainer: {
    justifyContent: "center",
    height: "100%",
    marginTop: "20%",
    textAlign: "center",
    zIndex: "999999999999999",
  },
});
